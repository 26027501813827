import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import reload from 'theme/assets/images/reload.svg';
import CourseNextStepButton from 'modules/common/CourseNextStepButton';

import {
  fixedHeight,
  heightDescription,
  pursue,
  mt8,
  paddingBox,
  brCustom1,
  brCustom2,
  pvButton,
  customMarginBottom,
  flexedBox,
  pictureBox,
  detailsBox,
  customShadow,
} from './styles.scss';

const DashboardTrack = ({
  name,
  description,
  slug,
  courses,
  isTrackCompleted,
  backgroundImage,
  isDegree,
  icon,
  subscription,
}) => {
  const styleImage = {
    background: `linear-gradient(to right, rgba(0,0,0,0.552), rgba(0,0,0,0.552)), url(${backgroundImage &&
      backgroundImage.url})`,
    backgroundSize: 'cover',
    backgroundPosition: '50%',
  };
  return (
    <div className={`w-100 ${pursue} tr-ns tc`}>
      <div className="w-70-ns w-80-m w-90 center pv5-ns pv3">
        <div className={`tr-ns tc-m pt3 ${customMarginBottom}`}>
          <h2 className="white" data-cy="dashboard-title">
            <FormattedMessage id="complete_your_career" />
          </h2>
        </div>
        <div
          className={`w-100 flex flex-row-reverse-ns flex-column-m flex-column items-center ${customShadow}`}
        >
          <div
            className={`${fixedHeight} pv6 ${brCustom1} ${flexedBox} ${pictureBox} tc`}
            style={styleImage}
          >
            <CourseNextStepButton
              className={`bg-green-light bg-animate hover-bg-green ${pvButton}`}
              trackSlug={slug}
              courses={courses}
              isDegree={isDegree}
              subscription={subscription}
            >
              <div className="flex justify-between">
                <div className="ph1">
                  <FormattedMessage
                    id={
                      // eslint-disable-next-line no-nested-ternary
                      !isTrackCompleted
                        ? isDegree
                          ? 'continue_degree'
                          : 'continue_track'
                        : isDegree
                        ? 'review_degree'
                        : 'review_track'
                    }
                  />
                </div>
                {isTrackCompleted && <img src={reload} alt="reload" />}
              </div>
            </CourseNextStepButton>
          </div>
          <div
            className={`bg-white ${brCustom2} ${fixedHeight} ${flexedBox} ${detailsBox}`}
          >
            <div
              className={`${paddingBox} ph4 bb b--light-gray flex items-center justify-center flex-row justify-start-ns`}
            >
              <div className="flex flex-wrap justify-center">
                {icon && <img height="44" width="44" src={icon.url} alt="" />}
                <h3 className={`mh2 ${mt8}`}>{name}</h3>
              </div>
            </div>
            <div className={`mh3-ns pr2 ${heightDescription}`}>
              <p
                className="f5 mv0 light-silver"
                dangerouslySetInnerHTML={{
                  __html:
                    description > description.slice(0, 220)
                      ? `${description.slice(0, 180)}...`
                      : description,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardTrack.propTypes = {
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  courses: PropTypes.array.isRequired,
  isTrackCompleted: PropTypes.bool.isRequired,
  backgroundImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  isDegree: PropTypes.bool,
  icon: PropTypes.object.isRequired,
};

export default DashboardTrack;
