import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import DashBoardRouter from 'modules/dashboard/routes';

const DashboardPage = ({data}) => {
  return (
    <DashBoardRouter
      degrees={data.fugoki.degrees}
      tracks={data.fugoki.tracks}
    />
  );
};

DashboardPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DashboardPage;

export const pageQuery = graphql`
  query dashboardQuery($partner: String!) {
    fugoki {
      tracks: allTracks(partner: $partner) {
        edges {
          node {
            id
            name
            description
            level
            color
            backgroundImage {
              url
            }
            position
            soon
            status
            slug
            trailerYoutubeId
            icon {
              url
            }
            trackstepSet {
              edges {
                node {
                  id
                  course {
                    id
                    slug
                    name
                    stageSet {
                      edges {
                        node {
                          id
                          slug
                          name
                          stepSet {
                            edges {
                              node {
                                id
                                slug
                                status
                                duration
                                videotutorial {
                                  id
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      degrees: allDegrees(partner: $partner) {
        edges {
          node {
            id
            durationInMonths
            landingPageUrl
            level
            slug
            cohortSet {
              edges {
                node {
                  id
                  active
                  startDate
                }
              }
            }
            image {
              url
            }
            trackPtr {
              id
              name
              description
              level
              color
              backgroundImage {
                url
              }
              position
              soon
              status
              slug
              position
              trailerYoutubeId
              icon {
                url
              }
              trackstepSet {
                edges {
                  node {
                    id
                    course {
                      id
                      slug
                      name
                      stageSet {
                        edges {
                          node {
                            id
                            slug
                            name
                            stepSet {
                              edges {
                                node {
                                  id
                                  slug
                                  status
                                  duration
                                  videotutorial {
                                    id
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
