import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import GreenCaret from 'theme/assets/images/green-caret.svg';
import TracksLibraryIcon from 'theme/assets/images/tracks-library.svg';
import {tracksIcon} from './styles.scss';

const TracksLibraryBox = ({title, message, certificate, is_completed, cy}) => (
  <div className="w-100 tr-ns tc mb4">
    <div className={`${certificate ? '' : 'w-80-ns w-90 center'} pt5 pb0`}>
      <h4 className="f4 tr-ns tc black pb3 bb b--light-gray" data-cy={cy}>
        <FormattedMessage id={title} />
      </h4>
    </div>
    <div className={`${certificate ? '' : 'w-80-ns w-90 center'} mt4 mt0-ns`}>
      <p className="lh-copy tr-ns tc mt3">
        <FormattedMessage id={message} />{' '}
        <FormattedMessage id="discover_tracks_library_first_half" />{' '}
        <Link className="blue underline" to="/tracks">
          <FormattedMessage id="tracks" />
        </Link>{' '}
        <FormattedMessage id="discover_tracks_library_second_half" />
      </p>
      {!is_completed && (
        <Link
          to="/tracks"
          className={`ba b--light-gray pv5-ns pv3 pl0-ns pr4-ns ph2 mw7-ns mw0 mt4 flex items-start justify-between flex-row-ns flex-column ${tracksIcon}`}
        >
          <div className="flex2">
            <h4 className="f4 pb4 black flex items-center justify-start-ns justify-center">
              <FormattedMessage id="discover_tracks" />
              <img src={GreenCaret} className="mr2" alt="caret green" />
            </h4>
            <p className="lh-copy gray mw6-ns mw0 tr-ns tc">
              <FormattedMessage id="learn_programming_notice" />
            </p>
            <div className="dn-ns dn-m db flex justify-center">
              <img
                src={TracksLibraryIcon}
                className="mt2 dn-ns dn-m db"
                alt="tracks library"
                width="150px"
              />
            </div>
          </div>
          <div className="flex1" />
        </Link>
      )}
    </div>
  </div>
);

TracksLibraryBox.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
  certificate: PropTypes.bool,
  is_completed: PropTypes.bool,
  cy: PropTypes.string,
};

export default TracksLibraryBox;
