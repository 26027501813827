import {Query} from '@apollo/client/react/components';
import decodeId from 'core/libs/helpers/decodeId';
import withDegrees from 'core/libs/hoc/withDegrees';
import {navigate} from 'gatsby';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import TracksLibraryBox from 'modules/common/TracksLibraryBox';
import TracksWrapper from 'modules/common/TracksWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import {Helmet} from 'react-helmet';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {compose, setPropTypes, withProps, withPropsOnChange} from 'recompose';
import DashboardTrack from '../components/DashboardTrack';
import CURRENT_TRACK from '../graphql/currentTrack.graphql';
import SUBSCRIPTION from '../graphql/subscription.graphql';
import { useQuery } from '@apollo/client';

const {PARTNER} = process.env;

const DashboardContainer = (props) => {
  const { data } = useQuery(SUBSCRIPTION);
  const intl = useIntl();
  const {formatMessage} = intl;
  const {
    completedTracks,
    degreesAndTracks,
    progress,
    startedTracks,
    degrees,
  } = props;
  const subscriptionStatus = data?.recurrentPayment?.status;
  return (
    <div className="bg-white pb5">
      <Helmet
        title={`${formatMessage({
          id: 'in_progress',
        })} | ${formatMessage({
          id: PARTNER === 'BARMEJ' ? 'helmet_barmej' : 'partner_helmet',
        })}`}
      />
      <Query
        query={CURRENT_TRACK}
        fetchPolicy="network-only"
        variables={{partner: PARTNER}}
      >
        {({data, loading, error}) => {
          if (loading) return <LoadingIndicator />;
          if (error) return <p>ERROR: {error.message}</p>;
          if (!data && data.currentTrack) {
            return null;
          }
          if (!data.currentTrack) {
            navigate('/tracks');
            return null;
          }
          const isDegree = !!data.currentTrack.track.degree;
          const currentTrackId = decodeId(data.currentTrack.track.id);
          const isTrackCompleted =
            progress &&
            progress.track &&
            progress.track[currentTrackId] === 'completed';
          const currentTrack = degreesAndTracks.find(
            ({id}) => id === currentTrackId,
          );
          if (!currentTrack) {
            return null;
          }

          return (
            <DashboardTrack
              name={currentTrack.name}
              description={currentTrack.description}
              slug={currentTrack.slug}
              courses={currentTrack.courses}
              backgroundImage={
                isDegree ? currentTrack.image : currentTrack.backgroundImage
              }
              isTrackCompleted={isTrackCompleted}
              isDegree={isDegree}
              icon={currentTrack.icon}
              subscription={subscriptionStatus}
            />
          );
        }}
      </Query>
      {startedTracks.length > 0 ? (
        <TracksWrapper
          authenticated
          isDashboard
          stepsProgress={progress.step || {}}
          title="started_tracks"
          tracks={startedTracks}
          tracksProgress={progress.track || {}}
          cy="started-tracks-title"
          subscription={subscriptionStatus}
        />
      ) : (
        <TracksLibraryBox
          title="started_tracks"
          message="starting_tracks"
          cy="started-tracks-title"
        />
      )}
      {PARTNER === 'BARMEJ' && (
        <TracksWrapper
          authenticated
          title="available_nano_degrees"
          tracks={degrees}
          isDegreeBox
        />
      )}
      {completedTracks.length > 0 ? (
        <TracksWrapper
          authenticated
          isDashboard
          stepsProgress={progress.step || {}}
          title="completed_tracks"
          tracks={completedTracks}
          tracksProgress={progress.track || {}}
          cy="completed-tracks-title"
          subscription={subscriptionStatus}
        />
      ) : (
        <TracksLibraryBox
          title="completed_tracks"
          message="no_completed_tracks"
          is_completed
          cy="completed-tracks-title"
        />
      )}
    </div>
  );
};

DashboardContainer.propTypes = {
  completedTracks: PropTypes.array.isRequired,
  degreesAndTracks: PropTypes.array.isRequired,
  progress: PropTypes.object.isRequired,
  startedTracks: PropTypes.array.isRequired,
  degrees: PropTypes.array.isRequired,
};

const enhance = compose(
  connect((state) => {
    return {
      progress: state.user.progress,
    };
  }),
  withPropsOnChange(['degrees', 'tracks'], ({degrees, tracks}) => {
    const enhanceTrack = (track, degree = {}) => ({
      ...degree,
      ...track,
      isDegree: !!degree.id,
      id: decodeId(track.id),
      trackstepSet: undefined,
      courses: track.trackstepSet.edges.map(({node: {course}}) => ({
        ...course,
        id: decodeId(course.id),
        stageSet: undefined,
        stages: course.stageSet.edges.map(({node: stage}) => ({
          ...stage,
          id: decodeId(stage.id),
          stepSet: undefined,
          steps: stage.stepSet.edges.map(({node: step}) => ({
            ...step,
            id: decodeId(step.id),
          })),
        })),
      })),
    });
    return {
      degreesAndTracks: degrees.edges
        .map(({node: {trackPtr, ...degree}}) => enhanceTrack(trackPtr, degree))
        .concat(tracks.edges.map(({node: track}) => enhanceTrack(track))),
    };
  }),
  setPropTypes({
    degreesAndTracks: PropTypes.array,
  }),
  withPropsOnChange(
    ['progress', 'degreesAndTracks'],
    ({progress, degreesAndTracks}) => {
      return {
        completedTracks:
          progress && progress.track
            ? degreesAndTracks.filter(
                (track) =>
                  !track.soon && progress.track[track.id] === 'completed',
              )
            : [],
        startedTracks:
          progress && progress.track
            ? degreesAndTracks.filter(
                (track) =>
                  !track.soon && progress.track[track.id] === 'started',
              )
            : [],
      };
    },
  ),
  //withDegrees,
  withProps(({degrees}) => {
    return {
      degrees:
        degrees &&
        degrees.edges &&
        degrees.edges.map(({node: degree}) => ({
          ...degree,
          id: decodeId(degree.id),
          icon: degree && degree.trackPtr && degree.trackPtr.icon,
          name: degree && degree.trackPtr && degree.trackPtr.name,
          description: degree && degree.trackPtr && degree.trackPtr.description,
        })),
    };
  }),
);

export default enhance(DashboardContainer);
